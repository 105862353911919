import Geocode from 'react-geocode';

export const GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY;

Geocode.setApiKey(GOOGLE_API_KEY);

export function getDirection(salon) {
  window.open(
    `https://maps.google.com/?q=${salon.salonName} ${salon.street} ${salon.city}, ${salon.state}, ${salon.zip}`,
    '_blank'
  );
}

export async function getDistance(salon, search) {
  const salonLocation = await getSalonLocation(salon);
  const searchLocation = await getSearchLocation(search);
  const distance = getDistanceBetweenPoints(salonLocation, searchLocation);
  return `${distance} miles`;
}

export function getDistanceBetweenPoints(location1, location2) {
  if (
    !location2 ||
    !location2.position ||
    !location2.position.lat ||
    !location2.position.lng
  ) {
    return null;
  }
  return distance(
    location1.position.lat,
    location1.position.lng,
    location2.position.lat,
    location2.position.lng,
    'M'
  );
}

export async function getFilteredSalons(salons, searchString) {
  try {
    if (searchString === '') {
      return [];
    }
    const threshold = process.env.GATSBY_SALON_THRESHOLD_DISTANCE || 200;
    const searchLocation = await getSearchLocation(searchString);
    if (
      !searchLocation ||
      !searchLocation.position ||
      !searchLocation.position.lat ||
      !searchLocation.position.lng
    ) {
      return [];
    }
    const filteredSalons = [];
    salons.forEach(async salon => {
      const salonLocation = await getSalonLocation(salon);
      salon.distance = getDistanceBetweenPoints(salonLocation, searchLocation);
      if (salon.distance && parseFloat(salon.distance) < threshold) {
        filteredSalons.push(salon);
      }
    });
    return filteredSalons;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export function formatDistance(distanceInMiles) {
  return `${parseFloat(distanceInMiles).toFixed(2)} Miles`;
}

// returns distance between two points in miles
function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'Km') {
      dist = dist * 1.609344;
    }
    return dist;
  }
}

// Not available with CORS
// const MATRIX_URL = `https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=Washington,DC&destinations=New+York+City,NY&key=${GOOGLE_API_KEY}`;

// export async function getDistance() {
//   try {
//     let response = await fetch(MATRIX_URL, {
//       mode: 'no-cors',
//       headers: {
//         'Content-Type': 'application/json',
//         // 'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     });
//     let data = await response.json();
//     console.log(2222, data);
//   } catch (e) {
//     console.log(e);
//   }
// }

let locationCache = {};

export function getSalonString(salon) {
  return `${salon.salonName} ${salon.street} ${salon.city} ${salon.state} ${salon.zip}`;
}

export async function getSalonLocation(salon) {
  let string = getSalonString(salon);
  if (locationCache[string]) return locationCache[string];
  let result;
  try {
    let response = await Geocode.fromAddress(string);
    const { lat, lng } = response.results[0].geometry.location;
    result = { position: { lat, lng }, salon };
  } catch (e) {
    result = 'error';
  }
  locationCache[string] = result;
  return result;
}

export async function getSearchLocation(searchString) {
  let string = searchString;
  if (locationCache[string]) return locationCache[string];
  let result;
  try {
    let response = await Geocode.fromAddress(string);
    const { lat, lng } = response.results[0].geometry.location;
    result = { position: { lat, lng } };
  } catch (e) {
    result = 'error';
  }
  locationCache[string] = result;
  return result;
}

export async function getUserLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.error('No location available');
    }
  });
}
