import React, { useState, useEffect } from 'react';

import { Map, Container } from '@matchbox-mobile/kenra-storybook';
import mapSilverStyle from './mapSilverStyle';
import { GOOGLE_API_KEY, getSalonLocation } from './helpers';

export default function SalonLocatorMap({ salons, userLocation }) {
  let [markers, setMarkers] = useState([]);

  useEffect(() => {
    async function fetchLocations() {
      let markers = await Promise.all(
        salons.map(salon => getSalonLocation(salon))
      );
      setMarkers(
        markers
          .filter(marker => marker !== 'error')
          .map(({ position, salon }) => ({
            position,
            // clickable: true,
            // onClick: () => console.log(222, salon)
          }))
      );
    }
    fetchLocations();
  }, [salons]);

  return (
    <>
      <Container mobileWide>
        <Map
          options={{
            styles: mapSilverStyle,
          }}
          defaultCenter={userLocation || { lat: 39.5, lng: -98.35 }}
          markers={markers}
          defaultZoom={userLocation ? 6 : 4}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Container>
    </>
  );
}
