import React, { useState, useEffect } from 'react';

import SalonLocatorMap from './SalonLocatorMap';
import SalonLocatorList from './SalonLocatorList';

import { getUserLocation, getFilteredSalons } from './helpers';

import {
  Spacing,
  SharedStyles,
  Container,
  FieldInput,
  Fieldset,
  FieldSubmit,
  FormSearchStore,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionInfo, StTitle } = SharedStyles;

export default function SalonLocator({ salons }) {
  const [search, setSearch] = useState('');
  const [filteredSalons, setFilteredSalons] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [userLocation, setUserLocation] = useState(null);
  const [isFiltering, setIsFiltering] = useState('false');

  useEffect(() => {
    getUserLocation().then(newUserLocation => setUserLocation(newUserLocation));
  }, []);

  useEffect(() => {
    setIsFiltering('true');
    getFilteredSalons(salons, search)
      .then(newFilteredSalons => {
        setFilteredSalons(newFilteredSalons);
      })
      .then(() => {
        return new Promise(resolve => setTimeout(resolve, 500));
      })
      .then(() => {
        setIsFiltering('false');
      });
  }, [salons, search]);

  async function onSearch(e) {
    e.preventDefault();
    setSearch(searchFieldValue);
  }

  return (
    <>
      <Spacing removeSpaceBottom>
        <Container useMediumWidth>
          <FormSearchStore>
            <StSectionInfo>
              <StTitle colorBlack styleMedium>
                <h1>Find a salon near you</h1>
              </StTitle>
            </StSectionInfo>

            <form onSubmit={onSearch}>
              <Fieldset legend="Enter Zip Code">
                <FieldInput
                  type="text"
                  id="form-zip-code"
                  label="You may also enter country, town, or city"
                  value={searchFieldValue}
                  onChange={e => setSearchFieldValue(e.target.value)}
                />
              </Fieldset>
              <FieldSubmit>
                <input value="Search" type="submit" />
              </FieldSubmit>
            </form>
          </FormSearchStore>
        </Container>
      </Spacing>

      <Spacing
        removeSpaceBottom={
          !!(search && filteredSalons && isFiltering !== 'true')
        }
      >
        <SalonLocatorMap
          salons={
            search && filteredSalons && isFiltering !== 'true'
              ? filteredSalons
              : []
          }
          userLocation={userLocation}
        />
      </Spacing>

      {search && filteredSalons && isFiltering !== 'true' && (
        <SalonLocatorList salons={filteredSalons} search={searchFieldValue} />
      )}
    </>
  );
}
