import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SalonLocator from 'components/SalonLocator';

export default function SalonLocatorPage(props) {
  let {
    data: { allGoogleSheetSalonsRow },
  } = props;

  return (
    <Layout>
      <SalonLocator salons={allGoogleSheetSalonsRow.nodes} />
    </Layout>
  );
}

/*
  query {
    allContentfulSalon {
      nodes {
        name
        address
      }
    }
  }
*/

export const query = graphql`
  query {
    allGoogleSheetSalonsRow {
      nodes {
        salonName
        street
        city
        state
        zip
        phone
        website
      }
    }
  }
`;
