import React from 'react';

import { getDirection, formatDistance } from './helpers';

import {
  Spacing,
  SharedStyles,
  Container,
  ListInfo,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionInfo, StTitle } = SharedStyles;

export default function SalonLocatorList({ salons, search }) {
  salons.sort((a, b) => (a.distance > b.distance ? 1 : -1));
  return (
    <>
      <Spacing removeSpaceBottom className="only-desktop">
        <StSectionInfo>
          <Container>
            <StTitle colorBlack styleMedium>
              <h2>we found {salons.length} salons around you</h2>
            </StTitle>
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing>
        <Container useLargeWidth>
          <ListInfo
            showMobileTitleResults
            showMobilePin
            items={salons.map(salon => ({
              subtitle: formatDistance(salon.distance),
              title: salon.salonName,
              text: `${salon.street} ${salon.city}, ${salon.state}, ${salon.zip}`,
              textAction: 'GET DIRECTIONS',
              onClickTextAction: () => getDirection(salon),
              phone: salon.phone,
              website: salon.website,
            }))}
          />
        </Container>
      </Spacing>
    </>
  );
}
